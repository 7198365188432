<template>
  <div class="user-center">

    <Head headType="user" />
    <div class="user-container">
      <sideBar :sideBarList="userInfoList" @changeMenu="switchRouting" ref="sideBar" />
      <div class="user-center-view">
        <el-scrollbar class="comprehensive-box">
          <router-view></router-view>
        </el-scrollbar>
      </div>
    </div>
  </div>
</template>

<script>
import { userInfo } from '@/utils/options'
import Head from './../personCenter/components/head'
import sideBar from './../personCenter/components/sideBar.vue';
export default {
  data () {
    return {
      userInfoList: userInfo
    }
  },
  components: {
    Head,
    sideBar
  },
  mounted () {
    this.setCurrentShow()
  },
  methods: {
    switchRouting (index) {
      if (index === 0) {
        this.$router.push({ name: 'account' })
      } else if (index === 1) {
        this.$router.push({ name: 'enterprise' })
      }
    },
    setCurrentShow () {
      if (this.$route.fullPath === '/account') {
        this.$refs.sideBar.currentIndex = 0
      } else {
        this.$refs.sideBar.currentIndex = 1
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.user-center {
  height: 100vh;
  background-color: #f1f3f7;
  ::v-deep.el-scrollbar__wrap {
    overflow-x: hidden;
    // padding: 32px 64px;
  }
  ::v-deep.el-scrollbar__view {
    height: 100%;
  }
  ::v-deep .shadowBox {
    background: #ffffff;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
  }
  .user-container {
    display: flex;
    height: 100%;
    padding-top: 59px;
  }

  .user-center-view {
    height: 100%;
    flex: 1;
    padding-left: 220px;
    .title {
      line-height: 68px;
      height: 68px;
      margin: 0;
      border-bottom: 1px solid #c3cada;
      background: #fff;
      font-size: 14px;
      color: #303b50;
      font-weight: 700;
      padding-left: 32px;
    }
  }
}
.comprehensive-box {
  height: 100%;
}
.userSideBar {
  position: fixed;
  top: 59px;
}
</style>